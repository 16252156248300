.about-page-content {
  display: block;
  justify-content: center;
  display: block;
  min-width: 20rem;
  max-width: 80%;
}

.about-page-content-img-and-text {
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
  margin-bottom: 2rem;
  /* min-width: 80%; */
}

.about-page-content-img {
  width: 40%;
  border-radius: 1rem;
  align-self: center;
  /* margin: 2rem; */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.about-page-content-text {
  margin-left: 50px;
  line-height: 1.8rem;
}

.about-page-content-text-header {
  color: white;
  font-size: 30px;
  font-weight: bold;
  margin: 2rem 0rem;
}

.about-page-content-text-desc {
  color: #bbb;
  letter-spacing: 1px;
}

@media (max-width: 1415px) {
  .about-page-content {
    margin: 0rem 5rem;
  }

  .about-page-content-img-and-text {
    display: block;
    text-align: center;
  }
  .about-page-content-text-header {
    justify-content: center;
    margin-top: 40px;
    margin-bottom: 20px;
  }

  .about-page-content-img {
    width: 50%;
  }

  .about-page-content-text {
    width: 100%;
    margin: 0 auto;
  }
}

@media (max-width: 980px) {
  .about-page-content-img {
    width: 100%;
  }
}
