.portfolio-page-content {
  display: block;
  justify-content: center;
}

.portfolio-page-grid-container {
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 10px;
}

.portfolio-page-header-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.portfolio-page-header {
  width: auto;
  color: white;
  font-weight: bold;
  font-size: 30px;
  margin-bottom: 20px;
  padding: 10px;
}

.portfolio-page-header-link {
  color: white;
  text-decoration: underline;
  margin-top: 20px;
  margin-bottom: 20px;
}

@media (max-width: 1223px) {
  .portfolio-page-grid-container {
    display: block;
  }

  .portfolio-page-header-container {
    display: block;
    text-align: center;
    margin-bottom: 20px;
  }

  .portfolio-page-header {
    justify-content: center;
    margin-top: 40px;
    margin-bottom: 20px;
  }
}
